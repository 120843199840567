import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import ServerApiPage from '../pages/serverApiPage';
import ContactUsAndDownload from '../pages/contactUsAndDownload';
import FaceSwipe from '../pages/faceSwipe';
import TextStyleImage from '../pages/textStyleImage';
import TextToImage from '../pages/textToImage';
import PortraitMaster from '../pages/portraitMaster';
import SuperfractionRepair from '../pages/superfractionRepair';
import ImageToImage from '../pages/imageToImage';
import FaceIdToImage from '../pages/faceIdToImage';
import StyleIdToImage from '../pages/styleIdToImage';
import PoseFaceImage from '../pages/poseFaceImage';
import DoublePoseFaceImage from '../pages/doublePoseFaceImage';
import Toc from '../pages/toc';
import Privacy from '../pages/privacy';

export default function AppRouter() {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        async function fetchConfig() {
            try {
                const response = await fetch('/config.json?timestamp=' + Date.now(),{method: 'GET', headers: {'Content-Type': 'application/json'}});
                const data = await response.json();
                setConfig({
                    ip: data.ip,
                    router: data.router,
                    debug: data.debug,
                    token: data.token
                });
            } catch (error) {
                console.error('Error loading config:', error);
            }
        }
  
        fetchConfig();
    }, []);

    return (
        <Routes>
            {
                config ?
                <Route
                    path="/"
                    element={
                        config.router === "face_change" ?
                            <FaceSwipe config={config} /> :
                        config.router === "text_style_image" ?
                            <TextStyleImage config={config} /> :
                        config.router === "superfraction_repair" ?
                            <SuperfractionRepair config={config} /> :
                        config.router === "image_to_image" ?
                            <ImageToImage config={config} /> :
                        config.router === "faceId_to_image" ?
                            <FaceIdToImage config={config} /> :
                        config.router === "styleId_to_image" ?
                            <StyleIdToImage config={config} /> :
                        config.router === "pose_face_image" ?
                            <PoseFaceImage config={config} /> :
                        config.router === "double_pose_face_image" ?
                            <DoublePoseFaceImage config={config} />
                            : <></>
                    }
                /> : <></>
            }

            {/* <Route exact path="/" element={<Home/>} />
            <Route path="/home" element={<Home />} />
            <Route path="/serverApi" element={<ServerApiPage/>} />
            <Route path="/us_download" element={<ContactUsAndDownload />} />

            <Route path="/face_change" element={<FaceSwipe />}/>
            <Route path="/text_style_image" element={<TextStyleImage />}/>
            <Route path="/superfraction_repair" element={<SuperfractionRepair />} />
            <Route path="/image_to_image" element={<ImageToImage />} />
            <Route path="/faceId_to_image" element={<FaceIdToImage />} /> 
            <Route path="/styleId_to_image" element={<StyleIdToImage />}/>
            <Route path="/text_to_image" element={<TextToImage />}/>
            <Route path="/portrait_master" element={<PortraitMaster />}/> */}

            {/* <Route path="/toc" element={<Toc />} /> */}
            {/* <Route path="/privacy" element={<Privacy />} /> */}
        </Routes>
    );
};