import React, { useState, useEffect } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Menu, Avatar, Radio } from 'antd';
import './index.css';
import { Link, useLocation } from "react-router-dom";
import topRightLogo from '../../images/brand@2x.png'
import { useTranslation } from 'react-i18next';

const userLanguage = navigator.language;



const MyHeader = () => {
    const [current, setCurrent] = useState('home');
    const location = useLocation();
    const { i18n, t } = useTranslation();

    const onChange = (e) => {
        try {
            i18n.changeLanguage(e.target.value);
        } catch (error) {}
    };

    const onClick = (e) => {
        setCurrent(e.key);
    };


    const items = [
        {
            label: <Link to='/home'>{t('header.home')}</Link>,
            key: 'home',
        },
        {
            label: <Link to='/serverApi'>{t('header.api')}</Link>,
            key: 'serverApi',
        },
        {
            label: <Link to='/us_download'>下载</Link>,
            key: 'us_download',
        },
    ];

    useEffect(() => {
        try {
            if (location.pathname) {
                const parts = location.pathname.split("/");
                if (parts[1]) {
                    setCurrent(parts[1])
                } else {
                    setCurrent('home')
                }
            }
        } catch (error) {}
    }, [location]);

    if (location.pathname === '/toc' || location.pathname === '/privacy') {
        return null;
    };

    return <div className="heart">
        <div className="menu">
            <img className="img" src={topRightLogo} alt="" />
            {/* <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} /> */}
        </div>
        <div className="userAndMenu">
            {/* <Radio.Group onChange={onChange} defaultValue={ userLanguage === 'zh-CN' ? 'zh' : 'en'} className="i18n-buts">
                <Radio.Button value="en">English</Radio.Button>
                <Radio.Button value="zh">中文</Radio.Button>
            </Radio.Group>
            <Avatar className="hover" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} size={40} /> */}
        </div>
    </div>
}

export default MyHeader;