import React, { useRef, useState, useEffect } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Select,
    Radio,
    Modal
} from 'antd';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    PlusOutlined,
    RollbackOutlined,
    DownloadOutlined,
    UpOutlined,
    DownOutlined
} from '@ant-design/icons';
import {
    StyleIdAPI,
    StyleIdGetStyleNode
} from '../../api/request.js';
import TextAreaInput from '../../components/textAreaInput/index.jsx';
import PubInput from '../../components/pubInput/index.jsx';
import { useNavigate } from 'react-router-dom';
import 'react-resizable/css/styles.css';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const StyleIdToImage = (props) => {
    const { config } = props;
    const previewImageRef = useRef(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const [getImageUrl, setGetImageUrl] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [seed, setSeed] = useState('请输入种子');

    const [options, setOptions] = useState([]);

    const formRef = useRef(null);
    const navigate = useNavigate();

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <InboxOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return Upload.LIST_IGNORE;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('图像必须小于2MB!');
                return Upload.LIST_IGNORE;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return Upload.LIST_IGNORE;
        }
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };
    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const stochasticSeed = Date.now();
            const formData = new FormData();
            for (let i = 0; i < values.source.length; i++) {
                formData.append('sourceImage', values.source[i].originFileObj);
            }
            formData.append('img_width', Number(values.ratio.split(" ")[0]));
            formData.append('img_height',  Number(values.ratio.split(" ")[1]));
            formData.append('style', values.style);
            formData.append('num', 1);
            if (values.seed) {
                formData.append('seed', Number(values.seed));
            } else {
                setSeed(stochasticSeed);
                formData.append('seed', stochasticSeed);
            }
          
            const res = await StyleIdAPI({formData: formData, ip: config.ip, token: config.token});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，请重试');
            };
        } catch (error) {
            message.error('生成失败，请重试');
        };
        setDisabledSubmit(false);
    };
    const handleDownload = () => {
        const imageUrl = getImageUrl[0];

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getStyleNode = async () => {
        try {
            const response = await StyleIdGetStyleNode(config);
            let opData = [];
            if (response.code === 1000) {
                for (let i = 0; i < response.data.length; i++) {
                    opData.push({
                        label: response.data[i],
                        value: response.data[i]
                    });
                }
                setOptions(opData);
            }
        } catch (error) { };
    };

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x - targetRect.width/2,
            right: clientWidth - (targetRect.right - uiData.x) + targetRect.width/2,
            top: -targetRect.top + uiData.y - targetRect.height/2,
            bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height/2,
        });
    };
    const handleResize = (_event, { size }) => {
        const { width, height } = size;
        if (width >= 400) {
            setWidth(width);
        } else {
            setWidth(400);
        }
        if (height >= 400) {
            setHeight(height);
        } else {
            setHeight(400);
        }
    };
    useEffect(() => {
        if (previewOpen) {
            setWidth(400);
            setHeight(400);
        }
    }, [previewOpen]);

    useEffect(() => {
        getStyleNode();
        window.scrollTo(0, 0);
    }, []);

    return (<div className="styleid-to-image">
        {/* <div className="styleid-to-image-header">
            <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
            <div>Playground</div>
        </div> */}
        <div className="styleid-to-image-box">
            <div className="prompt">
                <Form
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    ref={formRef}
                    initialValues={{style: 'Pixar', ratio: '1024 1024'}}
                >
                    <div className="title">
                        <span>Source Image</span>
                        <Tooltip placement="top" title={'上传图片'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name="source"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        // noStyle
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload
                            action="/#"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}

                            name="source"
                            maxCount={4}
                            beforeUpload={beforeUpload}
                            className='upload-source'
                            disabled={disabledSubmit}
                        >
                            {fileList.length >= 4 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                    <Modal
                        open={previewOpen}
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    cursor: 'move',
                                }}
                                onMouseOver={() => {
                                    if (disabled) {
                                        setDisabled(false);
                                    }
                                }}
                                onMouseOut={() => {
                                    setDisabled(true);
                                }}
                            >
                                {previewTitle}
                                <div
                                    style={{
                                        width: previewImageRef?.current?.offsetWidth || 400,
                                        height: previewImageRef?.current?.offsetHeight || 400,
                                        position: 'absolute',
                                        top: '32px',
                                        right: 0,
                                        zIndex: 999
                                    }}
                                >
                                </div>
                            </div>
                        }
                        footer={null}
                        onCancel={handleCancel}
                        mask={false}
                        modalRender={(modal) => (
                            <Draggable
                                disabled={disabled}
                                bounds={bounds}
                                nodeRef={draggleRef}
                                onStart={(event, uiData) => onStart(event, uiData)}
                            >
                                <div ref={draggleRef}>{modal}</div>
                            </Draggable>
                        )}
                        width={width}
                        className="preview"
                    >
                        <Resizable
                            width={width}
                            height={height}
                            onResize={handleResize}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <img
                                    alt="example"
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        verticalAlign: 'middle',
                                        userDrag: 'none',
                                        WebkitUserDrag: 'none',
                                        userSelect: 'none',
                                        WebkitUserSelect: 'none',
                                        MozUserSelect: 'none',
                                        MsUserSelect: 'none'
                                    }}
                                    src={previewImage}
                                    ref={previewImageRef}
                                />
                            </div>
                        </Resizable>
                    </Modal>
                    <div style={{ display: 'block' }}>
                        {
                            options.length > 0 ?
                                <>
                                    <div className="title">
                                        <span>Style</span>
                                        <Tooltip placement="top" title={'生成的图片风格'} arrow={true}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <Form.Item
                                        name="style"
                                        // noStyle
                                        rules={[{ required: true, message: '请选择风格' }]}
                                    >
                                        <Select
                                            allowClear
                                            placeholder="请选择风格"
                                            className="style"
                                            options={options}
                                            disabled={disabledSubmit}
                                        />
                                    </Form.Item>
                                </> : <></>
                        }
                        <PubInput
                            title='Seed'
                            name='seed'
                            typr='number'
                            placeholder={seed}
                            disabled={disabledSubmit}
                            iconTitle='种子可以是随机也可以是固定，默认不输入就是随机'
                        />
                        <div className="title">
                            <span>Ratio</span>
                            <Tooltip placement="top" title={'生成的图片分辨率'} arrow={true}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <Form.Item
                            name="ratio"
                            noStyle
                        >
                            <Radio.Group className="ratio" disabled={disabledSubmit}>
                                <Radio.Button value="1024 1024">1024 * 1024</Radio.Button>
                                <Radio.Button value="1280 768">1280 * 768</Radio.Button>
                                <Radio.Button value="768 1280">768 * 1280</Radio.Button>
                                {/* {
                                    config.debug ?
                                    <Radio.Button value="856 1280">856 * 1280</Radio.Button> : <></>
                                } */}
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Form>
              
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc">
                    { getImageUrl ? <Image src={getImageUrl} className="img"/> : <></> }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成中，请稍等...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                            <Button
                                onClick={handleDownload}
                                className="but"
                                icon={<DownloadOutlined />}
                            ></Button>
                        </Tooltip> : <></>
                }
            </div>
        </div>
    </div>
    )
};
export default StyleIdToImage;