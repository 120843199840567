import React, { useEffect, useState } from "react";
import './index.css';
import {
    Select,
    Form,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';

const SelectInput = (props) => {
    const {
        title,
        name,
        placeholder = '',
        iconTitle = '',
        disabled = false,
        options=[]
    } = props;

    return (<div className="select-input">
       <div className="select-input-title">
            <span>{title}</span>
            <Tooltip placement="top" title={iconTitle} arrow={true}>
                <QuestionCircleOutlined />
            </Tooltip>
        </div>
        <Form.Item
            name={name}
            noStyle
        >
            <Select
                placeholder={placeholder}
                className="select-input-item"
                options={options}
                disabled={disabled}
            />
        </Form.Item>
    </div>
    )
};
export default SelectInput;