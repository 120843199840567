export const RATIOS = [
    {
        ratio: 0.6,
        width: 768,
        height: 1280
    },
    {
        ratio: 0.68,
        width: 832,
        height: 1216
    },
    {
        ratio: 0.72,
        width: 832,
        height: 1152
    },
    {
        ratio: 0.78,
        width: 896,
        height: 1152
    },
    {
        ratio: 0.82,
        width: 896,
        height: 1088
    },
    {
        ratio: 0.88,
        width: 960,
        height: 1088
    },
    {
        ratio: 0.94,
        width: 960,
        height: 1024
    },
    {
        ratio: 1,
        width: 1024,
        height: 1024
    },
    {
        ratio: 1.07,
        width: 1024,
        height: 960
    },
    {
        ratio: 1.13,
        width: 1088,
        height: 960
    },
    {
        ratio: 1.21,
        width: 1088,
        height: 896
    },
    {
        ratio: 1.29,
        width: 1152,
        height: 896
    },
    {
        ratio: 1.38,
        width: 1152,
        height: 832
    },
    {
        ratio: 1.46,
        width: 1216,
        height: 832
    },
    {
        ratio: 1.67,
        width: 1280,
        height: 768
    },
]
export const SCENE = [
    {
        value: '50s Retro Ads',
        label: '50s Retro Ads',
        children: [
            {
                value: '50s Coca Cola Ad',
                label: '50s Coca Cola Ad',
            },
            {
                value: '50s Love Card',
                label: '50s Love Card',
            },
            {
                value: '50s Ford Ad',
                label: '50s Ford Ad',
            },
            {
                value: '50s Cigarette Ad',
                label: '50s Cigarette Ad',
            },
            {
                value: '50s PanAm',
                label: '50s PanAm',
            },
            {
                value: '50s Diner',
                label: '50s Diner',
            },
            {
                value: '50s Suring Ad',
                label: '50s Suring Ad',
            },
            {
                value: '50s Chanel Ad',
                label: '50s Chanel Ad',
            },
            {
                value: '50s Hoover Vacuum Ad',
                label: '50s Hoover Vacuum Ad',
            },
            {
                value: '50s Whiskey',
                label: '50s Whiskey',
            },
            {
                value: '50s Disneyland',
                label: '50s Disneyland',
            },
            {
                value: '50s Wedding invitation card',
                label: '50s Wedding invitation card',
            },
            {
                value: '50s Baseball card',
                label: '50s Baseball card',
            },
        ],
    },
    {
        value: 'Date Night',
        label: 'Date Night',
        children: [
            {
                value: 'Valentines analog',
                label: 'Valentines analog',
            },
            {
                value: 'Vintage roses',
                label: 'Vintage roses',
            },
            {
                value: 'Neon heart',
                label: 'Neon heart',
            },
            {
                value: 'Y2k red',
                label: 'Y2k red',
            },
            {
                value: 'Cupid',
                label: 'Cupid',
            },
            {
                value: 'Redtaurant back pose',
                label: 'Redtaurant back pose',
            },
        ],
    },
    {
        value: 'Baby face',
        label: 'Baby face',
        children: [
            {
                value: 'Kids Classroom',
                label: 'Kids Classroom',
            },
            {
                value: 'Kids Basketball',
                label: 'Kids Basketball',
            },
            {
                value: 'Kids ski',
                label: 'Kids ski',
            },
            {
                value: 'Kids soccer',
                label: 'Kids soccer',
            },
            {
                value: 'Kids Private School',
                label: 'Kids Private School',
            },
            {
                value: 'Kids Church Wedding',
                label: 'Kids Church Wedding',
            },
        ],
    },
    {
        value: 'Vintage',
        label: 'Vintage',
        children: [
            {
                value: 'Photo with a poodle',
                label: 'Photo with a poodle',
            },
            {
                value: 'Retro red',
                label: 'Retro red',
            },
            {
                value: 'Glam Rock style',
                label: 'Glam Rock style',
            },
            {
                value: 'Analog style',
                label: 'Analog style',
            },
            {
                value: 'Vintage Apple Garden',
                label: 'Vintage Apple Garden',
            },
            {
                value: 'Backyard Car',
                label: 'Backyard Car',
            },
        ],
    },
    {
        value: 'Rich And Famous',
        label: 'Rich And Famous',
        children: [
            {
                value: 'Hollyhood Red Carpet',
                label: 'Hollyhood Red Carpet',
            },
            {
                value: 'Private Jet',
                label: 'Private Jet',
            },
            {
                value: 'Beverly Hills Hotel',
                label: 'Beverly Hills Hotel',
            },
            {
                value: 'Luxury yacht',
                label: 'Luxury yacht',
            },
            {
                value: 'Awards Show',
                label: 'Awards Show',
            },
            {
                value: 'Lambo',
                label: 'Lambo',
            },
        ],
    },
    {
        value: 'Sports',
        label: 'Sports',
        children: [
            {
                value: 'LA Dodgers',
                label: 'LA Dodgers',
            },
            {
                value: 'Sports Illustrated',
                label: 'Sports Illustrated',
            },
            {
                value: 'Kansas City Football',
                label: 'Kansas City Football',
            },
            {
                value: 'World cup trophy',
                label: 'World cup trophy',
            },
            {
                value: 'Lakers',
                label: 'Lakers',
            },
            {
                value: 'USC',
                label: 'USC',
            },
        ],
    },
    {
        value: 'Everyday Life',
        label: 'Everyday Life',
        children: [
            {
                value: 'Poolside',
                label: 'Poolside',
            },
            {
                value: 'Picnic Date',
                label: 'Picnic Date',
            },
            {
                value: '4:20',
                label: '4:20',
            },
            {
                value: 'Walgreens Pharmacy',
                label: 'Walgreens Pharmacy',
            },
            {
                value: 'Pep Boys auto',
                label: 'Pep Boys auto',
            },
            {
                value: 'Starbucks',
                label: 'Starbucks',
            },
        ],
    },
    {
        value: 'Fantasy',
        label: 'Fantasy',
        children: [
            {
                value: 'Comic book',
                label: 'Comic book',
            },
            {
                value: 'Hogwarts Castle',
                label: 'Hogwarts Castle',
            },
            {
                value: 'Fire',
                label: 'Fire',
            },
            {
                value: 'Centaurs',
                label: 'Centaurs',
            },
            {
                value: 'Shire from Lord of the Rings',
                label: 'Shire from Lord of the Rings',
            },
            {
                value: 'The Fairest of Them All',
                label: 'The Fairest of Them All',
            },
        ],
    },
    {
        value: 'Cholo',
        label: 'Cholo',
        children: [
            {
                value: 'cholo on the street',
                label: 'cholo on the street',
            },
            {
                value: 'cholo near supermarket',
                label: 'cholo near supermarket',
            },
            {
                value: 'cholo in the prison',
                label: 'cholo in the prison',
            },
            {
                value: 'cholo near lmpala',
                label: 'cholo near lmpala',
            },
            {
                value: 'cholo near wall',
                label: 'cholo near wall',
            },
            {
                value: 'cholo at the house',
                label: 'cholo at the house',
            },
        ],
    },
]