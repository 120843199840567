import React from "react";
import './index.css';
import {
    Input,
    Form,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';

const PubInput = (props) => {
    const {
        title,
        name,
        type = 'number',
        placeholder = '',
        iconTitle = '',
        disabled= false
    } = props;

    return (<div className="my-input">
        <div className="my-input-title">
            <span>{title}</span>
            <Tooltip placement="top" title={iconTitle} arrow={true}>
                <QuestionCircleOutlined />
            </Tooltip>
        </div>
        <Form.Item
            noStyle
            name={name}
        >
            <Input className="input" disabled={disabled} type={type} placeholder={placeholder}/>
        </Form.Item>
    </div>
    )
};
export default PubInput;