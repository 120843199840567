import React from 'react';
import './App.css';
import { Layout, Flex } from 'antd';
import MyHeader from './components/header';
import MyFooter from './components/footer';
import RouterView from './routes';
const { Header, Footer, Content } = Layout;

const App = () => {

    return (
        <Flex gap="middle" wrap="wrap" className='app'>
            <Layout className='layout-style'>
                {/* <Header className='header-style'>
                    <MyHeader />
                </Header> */}
                <Content className='content-style'>
                    <RouterView></RouterView>
                </Content>
                {/* <Footer className='footer-style'>
                    <MyFooter />
                </Footer> */}
            </Layout>
        </Flex>
    )
};
export default App;
