import React, { useRef, useEffect } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    RollbackOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FaceSwipeAPI } from '../../api/request.js'

const getBase64 = (img, callback) => {
    try {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    } catch (error) {}
};

const FaceSwipe = (props) => {
    const { config } = props;
    const [imageUrl, setImageUrl] = React.useState('');
    const [imageUrl2, setImageUrl2] = React.useState('');

    const [getImageUrl, setGetImageUrl] = React.useState('');
    const [disabledSubmit, setDisabledSubmit] = React.useState(false);

    const formRef = useRef(null);
    const navigate = useNavigate();

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('图像必须小于6MB!');
                return false;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return false;
        }
    };
    const handleChange = (info) => {
        if (info?.fileList?.length > 0) {
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
        }
    };

    const normFile2 = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload2 = async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('图像必须小于6MB!');
                return false;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return false;
        }
    };
    const handleChange2 = (info) => {
        if (info?.fileList?.length > 0) {
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl2(url);
            });
        }
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };
    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const formData = new FormData();
            formData.append('targetImage', values.pose[0].originFileObj);
            formData.append('sourceImage', values.face[0].originFileObj);
            const res = await FaceSwipeAPI({formData: formData, ip: config.ip, token: config.token});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，请重试');
            };
        } catch (error) { 
            message.error('生成失败，请重试');
        };
        setDisabledSubmit(false);
    };

    const handleDownload = () => {
        const imageUrl = getImageUrl;
    
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div className="face-swipe">
        {/* <div className="face-swipe-header">
            <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
            <div>Playground</div>
        </div> */}
        <div className="face-swipe-box">
            <div className="prompt">
                <div className="title">
                    <span>Prompt</span>
                    <Tooltip placement="top" title={'上传图片'} arrow={true}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </div>
                <Form
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    ref={formRef}
                    layout="inline"
                >
                    <Form.Item
                        name="pose"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload.Dragger
                            name="pose"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            showUploadList={{
                                showDownloadIcon: true,
                                downloadIcon: 'Download',
                                showRemoveIcon: true,
                                removeIcon: <DeleteOutlined onClick={(e) => { console.log(e, 'custom removeIcon event'); setImageUrl(''); }} />,
                            }}
                            className='upload-pose'
                            disabled={disabledSubmit}
                        >
                            {imageUrl ? (
                                <img src={imageUrl} alt="avatar" />
                            ) : (<>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-hint">单击或拖动文件到此区域</p>
                            </>
                        )}
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item
                        name="face"
                        valuePropName="fileList"
                        getValueFromEvent={normFile2}
                        noStyle
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload.Dragger
                            name="face"
                            maxCount={1}
                            beforeUpload={beforeUpload2}
                            onChange={handleChange2}
                            showUploadList={ {
                                showDownloadIcon: true,
                                downloadIcon: 'Download',
                                showRemoveIcon: true,
                                removeIcon: <DeleteOutlined onClick={(e) => { console.log(e, 'custom removeIcon event'); setImageUrl2(''); }} />,
                            }}
                            className="upload-face"
                            disabled={disabledSubmit}
                        >
                            {imageUrl2 ? (
                                <img src={imageUrl2} alt="avatar" />
                                ) : (<>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-hint">单击或拖动文件到此区域</p>
                                </>
                            )}
                        </Upload.Dragger>
                    </Form.Item>
                </Form>
                <div className="hint">
                    <div>Target Image</div>
                    <div>Source Image</div>
                </div>
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc">
                    { getImageUrl ? <Image src={getImageUrl} className="img"/> : <></> }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成中，请稍等...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                            <Button
                                onClick={handleDownload}
                                className="but"
                                icon={<DownloadOutlined />}
                            ></Button>
                        </Tooltip> : <></>
                }
            </div>
        </div>
    </div>
    )
};
export default FaceSwipe;